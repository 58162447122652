import React, { Component } from 'react'
import friedRice from '../../assets/fried-rice.jpg'
import jeeraRice from '../../assets/jeera-rice.jpg'
import plainRice from '../../assets/plain-rice.jpg'
import pulao from '../../assets/pulao.jpg'
import Product from '../Product'

class RiceSection extends Component {
    render() {
        return(
            <section className="section section-rice" id="rice">
                <h2 className="h2">Rice</h2>
                <div className="row">
                    <Product
                        id="fried-rice"
                        price="99"
                        url="/menu"
                        image={friedRice}
                        name="Fried Rice"
                    />
                    <Product
                        id="jeera-rice"
                        price="99"
                        url="/menu"
                        image={jeeraRice}
                        name="Jeera Rice"
                    />
                    <Product
                        id="plain-rice"
                        price="79"
                        url="/menu"
                        image={plainRice}
                        name="Plain Rice"
                    />
                    <Product
                        id="pulao"
                        price="99"
                        url="/menu"
                        image={pulao}
                        name="Pulao"
                    />
                </div>
            </section>
        )
    }
}

export default RiceSection
import React, { Component } from 'react'
import NavigationBar from '../NavigationBar'

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {
        speed: 200,
        offset: 60,
        updateURL: false
    })
}

class Header extends Component {
    render() {
        return(
            <header className="menu-header">
                <NavigationBar
                    id1="/menu/#vegetarian"
                    id2="/menu/#non-vegetarian"
                    id3="/menu/#breads"
                    id4="/menu/#rice"
                    text1="Veg"
                    text2="Non-veg"
                    text3="Breads"
                    text4="Rice"
                />
                <div className="menu-text-box">
                    <h1 className="h1">Menu</h1>
                </div>
            </header>
        )
    }
}

export default Header
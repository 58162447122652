import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'

class Product extends Component {
    render() {
        return(
            <div className="col-lg-4 col-md-6">
                <div className="product-box">
                    <div className="product-image-div">
                        <LazyLoad once offset={100}>
                            <img src={this.props.image} alt={this.props.name} className="product-image" />
                        </LazyLoad>
                    </div>
                    <h3 className="h3">{this.props.name}</h3>
                    <p className="product-price">Rs. {this.props.price}</p>
                    <div>
                        <button className="snipcart-add-item menu-button"
                            data-item-id={this.props.id}
                            data-item-price={this.props.price}
                            data-item-url={this.props.url}
                            data-item-description={this.props.description}
                            data-item-image={this.props.image}
                            data-item-name={this.props.name}
                            data-item-taxable={true}
                            data-item-taxes="GST"
                            data-item-has-taxes-included={false}
                        >
                            Add To Cart
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Product
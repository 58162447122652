import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import thumbnail from '../../static/thumbnail.png'
import Header from '../components/Menu/Header'
import VegetarianSection from '../components/Menu/VegetarianSection'
import NonVegetarianSection from '../components/Menu/NonVegetarianSection'
import BreadsSection from '../components/Menu/BreadsSection'
import RiceSection from '../components/Menu/RiceSection'
import Footer from '../components/Footer'

class Menu extends Component {
    state = { dark: false }
    
    render() {
        return(
            <div>
                <Helmet
                    htmlAttributes={{
                        lang: 'en',
                    }}
                >
                    <meta charSet="utf-8" />
                    {/* Robot Meta Tags */}
                    <meta name="robots" content="noindex,nofollow,noarchive" />

                    {/* HTML Meta Tags */}
                    <title>Food Quickie | Menu</title>
                    <meta name="description" 
                    content="Welcome to Food Quickie! Enjoy our delicious meals prepared by some of the best chefs in the country, delivered quickly to your doorstep." />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" content="Food Quickie | Menu" />
                    <meta itemprop="description" content="Welcome to Food Quickie! Enjoy our delicious meals prepared by some of the best chefs in the country, delivered quickly to your doorstep." />
                    <meta itemprop="image" content={`https://ecommerce.amolgarhwal.com${thumbnail}`} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://ecommerce.amolgarhwal.com/menu" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Food Quickie | Menu" />
                    <meta property="og:description" content="Welcome to Food Quickie! Enjoy our delicious meals prepared by some of the best chefs in the country, delivered quickly to your doorstep." />
                    <meta property="og:image" content={`https://ecommerce.amolgarhwal.com${thumbnail}`} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Food Quickie | Menu" />
                    <meta name="twitter:description" content="Welcome to Food Quickie! Enjoy our delicious meals prepared by some of the best chefs in the country, delivered quickly to your doorstep." />
                    <meta name="twitter:image" content={`https://ecommerce.amolgarhwal.com${thumbnail}`} />

                </Helmet>
                <Header />
                <VegetarianSection />
                <NonVegetarianSection />
                <BreadsSection />
                <RiceSection />
                <Footer />
            </div>
        )
    }
}

export default Menu
import React, { Component } from 'react'
import butterNaan from '../../assets/butter-naan.jpg'
import butterRoti from '../../assets/butter-roti.jpg'
import plainNaan from '../../assets/plain-naan.jpg'
import plainRoti from '../../assets/plain-roti.jpg'
import rumaliRoti from '../../assets/rumali-roti.jpg'
import tandooriRoti from '../../assets/tandoori-roti.jpg'
import Product from '../Product'

class BreadsSection extends Component {
    render() {
        return(
            <section className="section section-breads" id="breads">
                <h2 className="h2">Breads</h2>
                <div className="row">
                    <Product
                        id="butter-naan"
                        price="39"
                        url="/menu"
                        image={butterNaan}
                        name="Butter Naan"
                    />
                    <Product
                        id="butter-roti"
                        price="19"
                        url="/menu"
                        image={butterRoti}
                        name="Butter Roti"
                    />
                    <Product
                        id="plain-naan"
                        price="29"
                        url="/menu"
                        image={plainNaan}
                        name="Plain Naan"
                    />
                    <Product
                        id="plain-roti"
                        price="9"
                        url="/menu"
                        image={plainRoti}
                        name="Plain Roti"
                    />
                    <Product
                        id="rumali-roti"
                        price="19"
                        url="/menu"
                        image={rumaliRoti}
                        name="Rumali Roti"
                    />
                    <Product
                        id="tandoori-roti"
                        price="19"
                        url="/menu"
                        image={tandooriRoti}
                        name="Tandoori Roti"
                    />
                </div>
            </section>
        )
    }
}

export default BreadsSection
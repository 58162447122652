import React, { Component } from 'react'
import butterChicken from '../../assets/butter-chicken.jpg'
import chicken65 from '../../assets/chicken-65.jpg'
import chickenBiryani from '../../assets/chicken-biryani.jpg'
import chickenTikka from '../../assets/chicken-tikka.jpg'
import muttonKeema from '../../assets/mutton-keema.jpg'
import muttonKorma from '../../assets/mutton-korma.jpg'
import muttonRoganjosh from '../../assets/mutton-roganjosh.jpg'
import pomfretFry from '../../assets/pomfret-fry.jpg'
import tandooriChicken from '../../assets/tandoori-chicken.jpg'
import tandooriShrimp from '../../assets/tandoori-shrimp.jpg'
import Product from '../Product'

class NonVegetarianSection extends Component {
    render() {
        return(
            <section className="section section-non-vegetarian" id="non-vegetarian">
                <h2 className="h2">Non Vegetarian</h2>
                <div className="row">
                    <Product
                        id="butter-chicken"
                        price="149"
                        url="/menu"
                        image={butterChicken}
                        name="Butter Chicken"
                    />
                    <Product
                        id="chicken-65"
                        price="149"
                        url="/menu"
                        image={chicken65}
                        name="Chicken 65"
                    />
                    <Product
                        id="chicken-biryani"
                        price="149"
                        url="/menu"
                        image={chickenBiryani}
                        name="Chicken Biryani"
                    />
                    <Product
                        id="chicken-tikka"
                        price="149"
                        url="/menu"
                        image={chickenTikka}
                        name="Chicken Tikka"
                    />
                    <Product
                        id="mutton-keema"
                        price="149"
                        url="/menu"
                        image={muttonKeema}
                        name="Mutton Keema"
                    />
                    <Product
                        id="mutton-korma"
                        price="149"
                        url="/menu"
                        image={muttonKorma}
                        name="Mutton Korma"
                    />
                    <Product
                        id="mutton-roganjosh"
                        price="149"
                        url="/menu"
                        image={muttonRoganjosh}
                        name="Mutton Roganjosh"
                    />
                    <Product    
                        id="pomfret-fry"
                        price="149"
                        url="/menu"
                        image={pomfretFry}
                        name="Pomfret Fry"
                    />
                    <Product
                        id="tandoori-chicken"
                        price="149"
                        url="/menu"
                        image={tandooriChicken}
                        name="Tandoori Chicken"
                    />
                    <Product
                        id="tandoori-shrimp"
                        price="149"
                        url="/menu"
                        image={tandooriShrimp}
                        name="Tandoori Shrimp"
                    />
                </div>
            </section>
        )
    }
}

export default NonVegetarianSection
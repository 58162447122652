import React, { Component } from 'react'
import butterPaneer from '../../assets/butter-paneer.jpg'
import idliSambar from '../../assets/idli-sambar.jpg'
import malaiKofta from '../../assets/malai-kofta.jpg'
import masalaDosa from '../../assets/masala-dosa.jpg'
import matarPaneer from '../../assets/matar-paneer.jpg'
import palakPaneer from '../../assets/palak-paneer.jpg'
import paneerTikka from '../../assets/paneer-tikka.jpg'
import pavBhaji from '../../assets/pav-bhaji.jpg'
import soyaChaap from '../../assets/soya-chaap.jpg'
import vegBiryani from '../../assets/veg-biryani.jpg'
import Product from '../Product'

class VegetarianSection extends Component {
    render() {
        return(
            <section className="section section-vegetarian" id="vegetarian">
                <h2 className="h2">Vegetarian</h2>
                <div className="row">
                    <Product
                        id="butter-paneer"
                        price="99"
                        url="/menu"
                        image={butterPaneer}
                        name="Butter Paneer"
                    />
                    <Product
                        id="idli-sambar"
                        price="99"
                        url="/menu"
                        image={idliSambar}
                        name="Idli Sambar"
                    />
                    <Product
                        id="malai-kofta"
                        price="99"
                        url="/menu"
                        image={malaiKofta}
                        name="Malai Kofta"
                    />
                    <Product
                        id="masala-dosa"
                        price="99"
                        url="/menu"
                        image={masalaDosa}
                        name="Masala Dosa"
                    />
                    <Product
                        id="matar-paneer"
                        price="99"
                        url="/menu"
                        image={matarPaneer}
                        name="Matar Paneer"
                    />
                    <Product
                        id="palak-paneer"
                        price="99"
                        url="/menu"
                        image={palakPaneer}
                        name="Palak Paneer"
                    />
                    <Product
                        id="paneer-tikka"
                        price="99"
                        url="/menu"
                        image={paneerTikka}
                        name="Paneer Tikka"
                    />
                    <Product
                        id="pav-bhaji"
                        price="99"
                        url="/menu"
                        image={pavBhaji}
                        name="Pav Bhaji"
                    />
                    <Product
                        id="soya-chaap"
                        price="99"
                        url="/menu"
                        image={soyaChaap}
                        name="Soya Chaap"
                    />
                    <Product
                        id="veg-biryani"
                        price="99"
                        url="/menu"
                        image={vegBiryani}
                        name="Veg Biryani"
                    />
                </div>
            </section>
        )
    }
}

export default VegetarianSection